import React, { useEffect, useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import styles from "../styles/Login.module.css";
import Lottie from "react-lottie";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";

interface OTPVerificationProps {
  onFormSwitch?: (message: any) => void;
}

const baseURL = process.env.REACT_APP_API_URL;
const headerKey = process.env.REACT_APP_API_HEADER_KEY;

export const OTPVerification: React.FC<OTPVerificationProps> = ({
  onFormSwitch,
}: OTPVerificationProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [verification, setVerification] = useState<{
    email: string;
    otp: string;
  }>({
    email: "",
    otp: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }

    const storedEmail = sessionStorage.getItem("userEmail");
    if (storedEmail) {
      setVerification((prev) => ({ ...prev, email: storedEmail }));
    }
  }, [navigate]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerification({ ...verification, [e.target.name]: e.target.value });
  };

  const handleVerify = async () => {
    if (verification.email === "" || verification.otp === "") {
      enqueueSnackbar(" OTP is required.", { variant: "error" });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/auth/verify`, {
        email: verification.email,
        otp: verification.otp,
      });

      if (response?.data?.success) {
        setLoading(false);
        sessionStorage.setItem("accessToken", response.data?.accessToken);
        sessionStorage.setItem("userId", response.data?.userId);
        sessionStorage.setItem("companyId", response.data?.userId);
        sessionStorage.setItem("userName", response.data?.name);
        sessionStorage.setItem("userEmail", response.data?.email);

        navigate(
          "/home?userId=" +
            response.data?.userId +
            "&" +
            "companyId=" +
            response.data?.userId
        );

        enqueueSnackbar("OTP Verified, Login Successful !", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          response.data.message || "Verification failed. Please try again.",
          { variant: "error" }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Verification failed. Please try again.", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleVerify();
    }
  };

  return (
    <>
      <div
        className={styles.loginContainer}
        onKeyDown={handleKeyPress}
        tabIndex={0}
      >
        <div className={styles.title}>
          <p>EasySign</p>
        </div>

        <div className={styles.left}>
          <div className={styles.lottie}>
            <Lottie
              style={{
                borderRadius: "10%",
              }}
              options={{
                loop: true,
                autoplay: true,
                animationData: require(`../assets/Login.json`),
              }}
            />
          </div>
        </div>
        <div className={styles.border} />
        <div className={styles.right}>
          <div className={styles.card}>
            <div className={styles.loginTitle}>
              <p className={styles.upperInfo}>Verify your OTP</p>
            </div>
            <div className={styles.loginForm}>
              <div style={{ margin: "10px 0", padding: "0 35px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="terms"
                    style={{
                      color: "black",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Please check your email for the OTP and enter it below to
                    verify your account.
                  </label>
                </div>
              </div>

              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>Email</p>
                </div>
                <input
                  className={styles.inputFeild}
                  type="email"
                  name="email"
                  placeholder="Type in your email.."
                  value={verification.email}
                  //   onChange={onChange}
                  onKeyDown={handleKeyPress}
                  disabled
                />
              </div>
              <div className={styles.feild}>
                <div className={styles.label}>
                  <p>OTP</p>
                </div>
                <input
                  className={styles.inputFeild}
                  type="number"
                  name="otp"
                  placeholder="Type in your OTP.."
                  required
                  onChange={onChange}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <button
                className={styles.btn}
                onClick={handleVerify}
                disabled={loading}
              >
                Verify
                {loading && (
                  <CircularProgress
                    size={20}
                    color={"inherit"}
                    className={styles.loader}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
