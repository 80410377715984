import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import DocumentUpload from "../documentUploader/documentUpload";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface UploadPopupProps {
  open: boolean;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const UploadPopup: React.FC<UploadPopupProps> = ({ open, onClose }) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="upload-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="upload-dialog-title" style={{ cursor: "default" }}>
        <TitleContainer>
          Upload Template Documents
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </TitleContainer>
      </DialogTitle>
      <div
        className="flex justify-center items-center"
        style={{ width: "100%" }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "100%",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <DocumentUpload />
        </div>
      </div>
      <DialogActions>
        <StyledButton onClick={onClose}>Close</StyledButton>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default UploadPopup;
