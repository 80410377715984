import React from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import { RiUserAddLine } from "react-icons/ri";
import { GrSend } from "react-icons/gr";
import { FaFileSignature } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Link } from "react-router-dom";
import DocumentUpload from "../documentUploader/documentUpload";

interface UploadSectionMainProps {}

export const UploadSectionMain: React.FC<UploadSectionMainProps> = ({}) => {
  return (
    <>
      <div className="w-full border-secondary border-1.5 border p-8 rounded-5 border-opacity-70">
        <div
          style={{ marginTop: "-8vh", marginBottom: "5vh" }}
          className="grid grid-flow-row-dense grid-cols-4 grid-rows-1 auto-cols-max px-15 w-full"
        >
          <div className="flex items-center justify-center flex-col opacity-60 main-step">
            <MdOutlineCloudUpload
              color="#3366FF"
              className="geticon"
              size={75}
            />
            <p className="text-black  text-center">Upload documents</p>
          </div>
          <div className="flex items-center justify-center flex-col opacity-60 main-step">
            <RiUserAddLine color="#3366FF" className="geticon" size={75} />
            <p className="text-black  text-center">Add recipients</p>
          </div>
          <div className="flex items-center justify-center flex-col opacity-60 main-step">
            <GrSend color="#3366FF" className="geticon" size={75} />
            <p className="text-black  text-center">Send to recipients</p>
          </div>
          <div className="flex items-center justify-center flex-col opacity-60">
            <FaFileSignature color="#3366FF" className="geticon" size={75} />
            <p className="text-black  text-center">Get signed documents</p>
          </div>
        </div>
        {/* <div className="flex items-center justify-center mt-4">
        <p className="text-black opacity-70 font-semibold">
          Drag and Drop Files or{" "}
          <Link to="/manage">
            <span className="text-red">Browser Documents</span>
          </Link>
        </p>
      </div> */}

        {/*       
        <div>
          <div
            className="flex justify-center items-center mt-2"
            style={{
              marginTop: "5%",
              backgroundColor: "white",
              width: "80%",
              display: "block",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
              padding: "20px",
              borderRadius: "10px",
              margin: "0 auto",
            }}
          >
            <DocumentUpload />
          </div>
        </div> */}

        <div
          className="flex justify-center items-center mt-2"
          style={{ width: "100%" }}
        >
          <div
            style={{
              maxWidth: "800px",
              width: "100%",
            }}
          >
            <DocumentUpload />
          </div>
        </div>
      </div>
    </>
  );
};
