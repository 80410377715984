import React, { useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const AgreementModal = ({ isOpen, onClose, userName, userEmail }) => {
  const handleDownload = async () => {
    const content = document.getElementById("dialog-content");
    if (!content) return;

    // Use html2canvas to capture the content
    const canvas = await html2canvas(content, {
      scale: 2, // Higher scale improves quality
      scrollY: -window.scrollY, // Ensure modal content is fully captured
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    // Constants for A4 page size and margins
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 8; // 12mm margin
    const availableHeight = pdfHeight - 2 * margin; // Content area height with margins

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth - 2 * margin; // Width considering margins
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // Calculate height preserving aspect ratio

    let position = 0; // Initial position for content rendering

    // Add content to the PDF page by page
    while (position < imgHeight) {
      const remainingHeight = imgHeight - position; // Remaining height of image
      const renderHeight = Math.min(availableHeight, remainingHeight); // Height to render on current page

      // Add image to current page
      pdf.addImage(
        imgData,
        "PNG",
        margin, // X position (left margin)
        margin - position, // Adjust Y position based on current position
        imgWidth,
        renderHeight,
        "",
        "FAST" // For faster rendering
      );

      // Move the position down by the rendered height
      position += renderHeight;

      // If there's more content left to render, create a new page
      if (position < imgHeight) {
        pdf.addPage();
      }
    }

    // Download the generated PDF
    pdf.save("agreement-details.pdf");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          minHeight: "80vh",
          maxHeight: "90vh",
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Agreement Details
        </Typography>
        <div
          style={{ display: "flex", position: "absolute", right: 8, top: 8 }}
        >
          <IconButton
            aria-label="download"
            onClick={handleDownload}
            style={{ marginRight: "10px" }}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <Divider />

      <DialogContent
        style={{
          padding: "20px",
          lineHeight: 1.6,
          overflowY: "auto",
        }}
      >
        <div id="dialog-content">
          <Typography variant="body1" paragraph>
            <strong>ELECTRONIC RECORD AND SIGNATURE DISCLOSURE</strong>
            <br />
            From time to time,{" "}
            <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
              {userName}
            </span>{" "}
            (we, us or Company) may be required by law to provide to you certain
            written notices or disclosures. Described below are the terms and
            conditions for providing to you such notices and disclosures
            electronically through the EasySign system. Please read the
            information below carefully and thoroughly, and if you can access
            this information electronically to your satisfaction and agree to
            this Electronic Record and Signature Disclosure (ERSD), please
            confirm your agreement by selecting the check-box next to ‘I agree
            to use electronic records and signatures’ before clicking ‘CONTINUE’
            within the EasySign system.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Getting paper copies</strong>
            <br />
            At any time, you may request from us a paper copy of any record
            provided or made available electronically to you by us. You will
            have the ability to download and print documents we send to you
            through the EasySign system during and immediately after the signing
            session and, if you elect to create a EasySign account, you may
            access the documents for a limited period of time (usually 30 days)
            after such documents are first sent to you. After such time, if you
            wish for us to send you paper copies of any such documents from our
            office to you, you will be charged a $0.00 per-page fee. You may
            request delivery of such paper copies from us by following the
            procedure described below.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Withdrawing your consent</strong>
            <br />
            If you decide to receive notices and disclosures from us
            electronically, you may at any time change your mind and tell us
            that thereafter you want to receive required notices and disclosures
            only in paper format. How you must inform us of your decision to
            receive future notices and disclosure in paper format and withdraw
            your consent to receive notices and disclosures electronically is
            described below.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Consequences of changing your mind</strong>
            <br />
            If you elect to receive required notices and disclosures only in
            paper format, it will slow the speed at which we can complete
            certain steps in transactions with you and delivering services to
            you because we will need first to send the required notices or
            disclosures to you in paper format, and then wait until we receive
            back from you your acknowledgment of your receipt of such paper
            notices or disclosures. Further, you will no longer be able to use
            the EasySign system to receive required notices and consents
            electronically from us or to sign electronically documents from us.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              All notices and disclosures will be sent to you electronically
            </strong>
            <br />
            Unless you tell us otherwise in accordance with the procedures
            described herein, we will provide electronically to you through the
            EasySign system all required notices, disclosures, authorizations,
            acknowledgements, and other documents that are required to be
            provided or made available to you during the course of our
            relationship with you. To reduce the chance of you inadvertently not
            receiving any notice or disclosure, we prefer to provide all of the
            required notices and disclosures to you by the same method and to
            the same address that you have given us. Thus, you can receive all
            the disclosures and notices electronically or in paper format
            through the paper mail delivery system. If you do not agree with
            this process, please let us know as described below.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              How to contact{" "}
              <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
                {userName}
              </span>
              :
            </strong>
            <br />
            You may contact us to let us know of your changes as to how we may
            contact you electronically, to request paper copies of certain
            information from us, and to withdraw your prior consent to receive
            notices and disclosures electronically as follows:
            <br />
            To contact us by email send messages to:{" "}
            <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
              {userEmail}
            </span>
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              To advise{" "}
              <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
                {userName}
              </span>{" "}
              of your new email address
            </strong>
            <br />
            To let us know of a change in your email address where we should
            send notices and disclosures electronically to you, you must send an
            email message to us at{" "}
            <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
              {userEmail}
            </span>
            and in the body of such request you must state: your previous email
            address, your new email address. We do not require any other
            information from you to change your email address.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              To request paper copies from{" "}
              <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
                {userName}
              </span>
            </strong>
            <br />
            To request delivery from us of paper copies of the notices and
            disclosures previously provided by us to you electronically, you
            must send us an email to{" "}
            <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
              {userEmail}
            </span>
            and in the body of such request you must state your email address,
            full name, mailing address, and telephone number.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              To withdraw your consent with{" "}
              <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
                {userName}
              </span>
            </strong>
            <br />
            To inform us that you no longer wish to receive future notices and
            disclosures in electronic format you may :
            <br />
            i. Decline to sign a document from within your signing session, and
            on the subsequent page, select the check-box indicating you wish to
            withdraw your consent, or you may;
            <br />
            ii. Send us an email to{" "}
            <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
              {userEmail}
            </span>{" "}
            and in the body of such request you must state your email, full
            name, mailing address, and telephone number.
          </Typography>

          {/* <Typography variant="body1" paragraph>
          <strong>Required hardware and software</strong>
          <br />
          The minimum system requirements for using the EasySign system may
          change over time. The current system requirements are found here:
          <span style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}>
            <a
              target="_blank"
              href="https://support.EasySign.com/s/document-item?bundleId=yca1573855023892&topicId=thx1573854978578.html&_LANG=enus&language=en_US&rsc_301"
            >
              &nbsp; EasySign System Requirements
            </a>
          </span>
        </Typography> */}

          <Typography variant="body1" paragraph>
            <strong>
              Acknowledging your access and consent to receive materials
              electronically
            </strong>
            <br />
            To confirm to us that you can access this information
            electronically, which will be similar to other electronic notices
            and disclosures that we will provide to you, please verify that you
            were able to read this electronic disclosure and that you also were
            able to print on paper or electronically save this page for your
            future reference and access or that you were able to email this
            disclosure and consent to an address where you will be able to print
            on paper or save it for your future reference and access. Further,
            if you consent to receiving notices and disclosures exclusively in
            electronic format on the terms and conditions described above,
            please let us know by checking the check-box next to ‘I agree to use
            electronic records and signatures’ before clicking ‘CONTINUE’ within
            the EasySign system.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              By selecting the check-box next to ‘I agree to use electronic
              records and signatures’, you confirm that:
            </strong>
            <br />
            <ul>
              <li>
                You can access and read this Electronic Record and Signature
                Disclosure; and
              </li>
              <li>
                You can print on paper this disclosure or save or send this
                disclosure to a place where you can print it, for future
                reference and access; and
              </li>
              <li>
                Until or unless you notify{" "}
                <span
                  style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}
                >
                  {userName}
                </span>{" "}
                as described above, you consent to receive exclusively through
                electronic means all notices, disclosures, authorizations,
                acknowledgements, and other documents that are required to be
                provided or made available to you by{" "}
                <span
                  style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}
                >
                  {userName}
                </span>{" "}
                during the course of your relationship with{" "}
                <span
                  style={{ fontWeight: "bold", backgroundColor: "#fff8c4" }}
                >
                  {userName}
                </span>
                .
              </li>
            </ul>
          </Typography>
        </div>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgreementModal;
