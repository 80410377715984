import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Typography,
  Link,
} from "@mui/material";
import AgreementModal from "./AgreementModal";
import { FileCopy } from "@mui/icons-material";

const MainModal = ({ isOpen, onClose, userName, userEmail }) => {
  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleAgreeClick = () => {
    if (isChecked) {
      onClose(); // Close the main modal
    }
  };

  // Prevent closing when clicking outside or on close button
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose(); // Only close if not due to backdrop click or escape key
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            padding: "10px",
            cursor: "default", // Default cursor for the entire dialog
          },
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            color: "#007BFF", // Change this color to your preferred one
            fontSize: "1.5rem",
            cursor: "default", // Default cursor
          }}
        >
          Agreement
        </DialogTitle>

        <hr style={{ border: "0.5px solid #ddd" }} />

        <DialogContent
          style={{
            cursor: "default", // Default cursor
          }}
        >
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: "bold", marginBottom: "20px" }}
          >
            Please read the Electronic Record and Signature Disclosure
          </Typography>

          {/* Link to open the Agreement Modal */}
          <Typography
            variant="body2"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              fontSize: "1.2rem",
              cursor: "default",
            }}
          >
            <FileCopy style={{ marginRight: "8px", color: "#007BFF" }} />{" "}
            {/* File-type icon */}
            <Link
              component="button"
              onClick={() => setAgreementModalOpen(true)}
              style={{
                color: "#007BFF",
                textDecoration: "none",
                transition: "color 0.3s, text-decoration 0.3s",
                cursor: "pointer", // Pointer cursor for the link
              }}
              onMouseEnter={(e) => {
                e.target.style.textDecoration = "underline";
                e.target.style.color = "#0056b3"; // Change this to your preferred hover color
              }}
              onMouseLeave={(e) => {
                e.target.style.textDecoration = "none";
                e.target.style.color = "#007BFF";
              }}
            >
              Electronic Record and Signature Disclosure
            </Link>
          </Typography>

          {/* Checkbox to agree */}
          <label
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2rem",
              marginTop: "10px",
              cursor: "default",
            }}
          >
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
              style={{ cursor: "pointer" }} // Pointer cursor for the checkbox
            />
            <Typography
              variant="body2"
              style={{ marginLeft: "9px", cursor: "default", fontSize: "16px" }}
            >
              I agree to use electronic records and signatures.
            </Typography>
          </label>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgreeClick}
            disabled={!isChecked}
            style={{ cursor: "pointer" }} // Pointer cursor for the button
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      {/* Agreement Modal */}
      {isAgreementModalOpen && (
        <AgreementModal
          isOpen={isAgreementModalOpen}
          onClose={() => setAgreementModalOpen(false)}
          userName={userName}
          userEmail={userEmail}
        />
      )}
    </>
  );
};

export default MainModal;
