// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Chip, LinearProgress, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { fabric } from "fabric";
import { FaSave, FaSignature, FaUndo, FaAngleRight } from "react-icons/fa";

import { useSnackbar } from "notistack";
import axios from "axios";
import { SignPopupLocal } from "../signature/SignPopupLocal";
import { fontFamilies } from "../signature/Tabs/SignatureSelect";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { publicIpv4 } from "public-ip";
import MainModal from "./PopupModals/MainModal.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const handleCanvasConvertion = (canvasID: string) => {
  let c: any = document.getElementById(canvasID);
  let bg = c.toDataURL("image/png");
  let canvas = new fabric.Canvas(canvasID);

  canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas));
  // console.log(
  //   "handleCanvasConvertion: canvas.toDatalessJSON: ",
  //   canvas.toDatalessJSON()
  // );
  return canvas;
};

const signTypes = {
  initial: "733dc378-0f4f-4c6e-8efc-c6fb0f8861a0",
  text: "a29817b6-211b-41b9-b362-d681af47544b",
  sign: "f02cfd06-1d79-467f-a3d9-d9851d961c66",
  date: "bce97a04-4b32-459b-8e42-13f24ec32fd1",
};
export const componentIdKindMap: any = {
  "733dc378-0f4f-4c6e-8efc-c6fb0f8861a0": "initial",
  "f02cfd06-1d79-467f-a3d9-d9851d961c66": "sign",
  "a29817b6-211b-41b9-b362-d681af47544b": "text",
  "bce97a04-4b32-459b-8e42-13f24ec32fd1": "date",
};
export let fabricCanvas: any = {};

export const updateFabric = (fabric: any) => {
  let updatedFabric: any = fabric.map((fab: any, idx: any) => ({
    ...fab,
    componentList: fab.componentList.map((comp: any, idx: any) => ({
      componentId: comp.componentId,
      recipientId: comp.recipientId,
      kind: comp.kind,
      boundingBox: Object.values(comp.component.getBoundingRect()),
      imageBase64: comp.component.toDataURL({
        // withoutTransform: true,
      }),
    })),
  }));

  return updatedFabric;
};
let signData: any = null;
const setSignData = (data: any) => (signData = data);
interface SignDocumentProps {
  type: number;
  setType: any;
}

export const RecepientSign: React.FC<SignDocumentProps> = ({
  type,
  setType,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [numPages, setNumPages] = useState<number>(0);
  const pdfScaleFactor = 0.8;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>(0);
  const [documentDetails, setDocumentDetails] = useState<any>([]);
  const [documentPages, setDocumentPages] = useState<any>();
  const [replacedComponent, setReplacedComponent] = useState<any>({});
  const [loadedPages, setLoadedPages] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const [isSignedAll, setIsSignedAll] = useState<boolean>(false);
  const [saveDocumentId, setSaveDocumentId] = useState([]);
  const [currentZoomLevel, setCurrentZoomLevel] = useState<any>(100);
  const [onLoadZoomFactor, setOnLoadZoomFactor] = useState<number>(1);
  const [signPages, setSignPages] = useState<any[]>([]);
  const [currentSignPageIndex, setCurrentSignPageIndex] = useState<number>(0);
  const [reviewBtnText, setReviewBtnText] = useState<string>("Start");
  const [signAll, setSignAll] = useState<boolean>(false);
  const [componentListCount, setComponentListCount] = useState<any>();
  const [normalizedZoom, setnormalizedZoom] = useState(1);
  const [CurrentActiveDocument, setCurrentActiveDocument] = useState<any>(1);
  const [movingBtnTop, setMovingBtnTop] = useState("0");

  const [currentSelectedComponent, setCurrentSelectedComponent] = useState<any>(
    {
      componentId: "",
      type: "",
    }
  );
  const baseURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  function getSizes() {
    let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
    var out = Math.ceil(zoom);
    console.log("window.devicePixelRatio", window.devicePixelRatio, out);
    setCurrentZoomLevel(out);
    console.log("out", out);
    return out;
  }

  useEffect(() => {
    window.addEventListener("resize", getSizes, false);
    getSizes();
  });
  useEffect(() => {
    getDocumentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let zoomfactor = currentZoomLevel / 100;
    let systemZoom = window.devicePixelRatio;
    let adjustZoom = zoomfactor / systemZoom;
    setnormalizedZoom(adjustZoom);
  }, []);
  const [searchParams] = useSearchParams();
  const documentHeaderId = searchParams.get("documentHeaderId");
  const emailId = searchParams.get("emailId");
  const recipientId = searchParams.get("recipientId");

  //const documentHeaderId = "5506f6ae-6d26-4283-90ad-166d4a15fb2e";
  //const emailId = "ram@gmail.com";

  const HandlePageNumberInAnotherAbj = (allData: any) => {
    let pageArr: any = [];
    let data;
    let allPageArr = [];

    console.log("data dta dtatatataat", data);
    for (let i = 0; i < allData.length; i++) {
      data = allData[i].documentPages;

      data?.map((obj: any, index: number) => {
        // eslint-disable-next-line array-callback-return
        obj.componentList.map((arr: any) => {
          pageArr.push({ ...arr });
        });

        return obj;
      });

      allPageArr.push(
        pageArr.filter((obj: any) => {
          return obj.componentId !== signTypes.text;
        })
      );
      pageArr = [];
    }

    console.log("data pageArr", allPageArr);
    // console.log("pageArr",pageArr)
    setComponentListCount(allPageArr);
    // setSignPages(pageArr);
  };

  console.log("oness documentDetails documentDetails", replacedComponent);

  const isSignPresent = (latestReplacedComponent: any = null) => {
    let replacedComponentLocal = replacedComponent;

    if (latestReplacedComponent) {
      replacedComponentLocal = latestReplacedComponent;
      console.log("AAAA", latestReplacedComponent, replacedComponent);
    }

    let ConfrimGo = [];
    for (let i = 0; i < documentDetails.length; i++) {
      console.log("documentPages[i]", documentPages[i]);
      if (componentListCount[i].length == replacedComponentLocal[i].length) {
        ConfrimGo.push(true);
      }
    }

    if (documentDetails.length == ConfrimGo.length) {
      setReviewBtnText("Finish");
      setCurrentSignPageIndex(signPages?.length);
      return true;
    } else {
      return false;
    }
  };

  const goToAntherDocument = () => {
    let replacedComponentLocal = replacedComponent;

    if (currentDocumentIndex < documentDetails.length - 1) {
      setCurrentDocumentIndex(currentDocumentIndex + 1);
      setReviewBtnText("Start");
      setCurrentActiveDocument(currentDocumentIndex + 2);
      setCurrentDocumentIndex(currentDocumentIndex + 1);

      setDocumentPages(
        documentDetails[currentDocumentIndex + 1]?.documentPages
      );
      setCurrentPage(0);
      // let pageArr = documentDetails[
      //   idx
      // ]?.documentPages?.map((obj: any) => obj.pageNumber);
      // setSignPages(
      //   pageArr?.sort((a: number, b: number) => a > b)
      // );
      arrangeSignPages(
        documentDetails[currentDocumentIndex + 1]?.documentPages
      );
      setCurrentSignPageIndex(0);
      setReviewBtnText("Start");
    }
  };

  // console.log("componentListCount mybaby", componentListCount);
  // console.log("replacedComponent mybaby", replacedComponent);
  // console.log("currentDocumentIndex", currentDocumentIndex);
  // console.log("documentDetails documentDetails mybaby", documentDetails);

  const getDocumentDetails = async () => {
    const zlevel = getSizes();
    let zoomfactor = zlevel / 100;
    console.log("AllDocumentData zoomfactor", zoomfactor);
    setOnLoadZoomFactor(zoomfactor);
    let ipAddress = await publicIpv4();
    sessionStorage.setItem("ipAddress", ipAddress);
    axios
      .get(
        `${baseURL}/upload/get-prepared-document?documentHeaderId=${documentHeaderId}&emailId=${emailId}&recipientId=${recipientId}&ipAddress=${ipAddress}`
      )
      .then((res) => {
        let responceData = res.data;
        console.log("AllDocumentData data", responceData);
        if (responceData == "Already Signed") {
          enqueueSnackbar("Already Signed", { variant: "success" });
          navigate("/AlreadySignedMsg");
        } else if (responceData == "Document Expired") {
          enqueueSnackbar("Document Link Expired", { variant: "success" });
          navigate("/LinkExpiredMsg");
        } else {
          handleSetImcomingSignData(responceData[0]?.esignatures);
          let AllDocumentData = res.data.map((Data: any) => {
            let newDocumentPages = Data.documentPages?.map((items: any) => {
              let newComponentList = (items.componentList =
                items?.componentList.map((docsItems: any) => {
                  docsItems.boundingBox[0] *= zoomfactor;
                  docsItems.boundingBox[1] *= zoomfactor;
                  docsItems.boundingBox[2] *= zoomfactor;
                  docsItems.boundingBox[3] *= zoomfactor;
                  return docsItems;
                }));
              return { ...items, componentList: newComponentList };
            });

            return { ...Data, documentPages: newDocumentPages };
          });
          console.log("AllDocumentData", AllDocumentData);
          console.log("AllDocumentData res", res.data.length);

          setDocumentDetails(AllDocumentData);
          let array: any = [];
          for (let i = 0; i < res.data.length; i++) {
            array.push({ id: res.data[i].documentDetailsId, value: false });
          }
          setSaveDocumentId(array);
          let documentObj = res?.data[0];
          /*   ?.documentPages?.map((page: any) => {
          let newComponentList = (page.componentList = page?.componentList?.map(
            (item: any) => {
              item.boundingBox[0] *= zoomfactor;
              item.boundingBox[1] *= zoomfactor;
              item.boundingBox[2] *= zoomfactor;
              item.boundingBox[3] *= zoomfactor;
              return item;
            }
          )); */

          // return { ...page, componentList: newComponentList };
          // });
          setDocumentPages(res.data[0].documentPages);
          arrangeSignPages(res.data[0].documentPages);
          HandlePageNumberInAnotherAbj(res?.data);

          // let pageArr = res?.data[0]?.documentPages?.map(
          //   (obj: any) => obj.pageNumber
          // );

          // setSignPages(pageArr?.sort((a: number, b: number) => a > b));

          // console.log("res.data[0].documentPages", res.data[0]);
          // console.log(res.data);
        }
      });
    setLoading(false);
  };

  const handleSetImcomingSignData = (signdata: any) => {
    const data = {
      fontId: signdata.fontId ? signdata.fontId : null,
      signImage: signdata.signImage === "" ? null : signdata.signImage,
      initialImage: signdata.initialImage === "" ? null : signdata.initialImage,
      signText: signdata.signText === "" ? null : signdata.signText,
      initialText: signdata.initialText === "" ? null : signdata.initialText,
    };
    setSignData(data);
  };
  const totalDocumentsLength = documentDetails.length;
  console.log("totalDocumentsLength", totalDocumentsLength);

  const updateCurrentdocumentIndex = (doc: any) => {
    console.log("doc", doc);
    let currentnumber =
      documentDetails.findIndex((item: any, index: number) => {
        return item.convertedDocumentName === doc.convertedDocumentName;
      }) + 1;

    setCurrentActiveDocument(currentnumber);
  };
  console.log("documentPages", documentPages);
  console.log("setDocumentDetails", documentDetails);

  const arrangeSignPages = (data: any) => {
    let pageArr: any = [];
    data?.map((obj: any) => {
      // eslint-disable-next-line array-callback-return
      obj.componentList.map((arr: any) => {
        pageArr.push({ pageNumber: obj.pageNumber, ...arr });
      });

      return obj;
    });
    pageArr = pageArr?.sort((a: any, b: any) => {
      if (a.pageNumber === b.pageNumber) {
        return a.boundingBox[1] > b.boundingBox[1] ? 1 : -1;
      }
      return a.pageNumber > b.pageNumber;
    });
    // console.log("pageArr",pageArr)
    setSignPages(pageArr);
  };
  const saveDocumentDetails = async (data: any) => {
    return axios.post(`${baseURL}/upload/save-signed-doc`, data);

    // .then((res) => {
    //   let objIndex = saveDocumentId.findIndex(
    //     (obj: any) => obj.id === data.documentDetailsId
    //   );
    //   let arrayDoc: any = saveDocumentId;
    //   arrayDoc[objIndex].value = true;
    //   setSaveDocumentId(arrayDoc);

    //   enqueueSnackbar("Sign saved successfully", { variant: "success" });

    //   let checkFlag = -saveDocumentId.length;
    //   saveDocumentId.forEach((obj: any) => {
    //     if (obj.value === true) {
    //       checkFlag++;
    //     }
    //   });

    //   if (checkFlag === 0) setIsSignSaved(true);
    // })
    // .catch((err) =>
    //   {
    //     enqueueSnackbar("Failed to save sign", { variant: "error" })
    //   }
    // );
  };
  const completeSignProcess = (data: any) => {
    console.log("dataaaa: ", data);
    axios
      .post(`${baseURL}/upload/complete`, data)
      .then((res) => {
        enqueueSnackbar("signing completed", { variant: "success" });
        navigate("/thankyou");
      })
      .catch((err) =>
        enqueueSnackbar("Something went wrong", { variant: "error" })
      );
  };

  useEffect(() => {
    setLoading(true);
    fabricCanvas = {
      docId: documentDetails[currentDocumentIndex]?.documentDetailsId,
      fabric: {},
      pageCanvasRefs: {},
    };
    console.log("fabricCanvas in useEffect resetted", fabricCanvas);
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentDetails]);
  console.log("fabric canvas", fabricCanvas);

  useEffect(() => {
    if (numPages === 0) {
      return;
    }

    if (loadedPages < numPages) {
      return;
    }

    let pdfCanvas = document.querySelectorAll(".react-pdf__Page__canvas");
    pdfCanvas.forEach((_: any, pageNumber: any) => {
      // console.log("processing page", pageNumber);
    });
  }, [documentDetails, loadedPages, numPages]);

  // useEffect(() => {
  //   if (!placeholderdata) return;

  //   setTimeout(() => {
  //   //  showComponents();
  //     setLoading(false);
  //   }, 5000);
  // }, [currentDocument, placeholderdata]);
  const showComponents = (newReplacedComponent?: any) => {
    let zoomfactor = currentZoomLevel / 100;

    /*   let size = parseInt(event.target.value, 10);
    setTextSize(size);
    let canvas = fabricCanvas[currentDocumentIndex].fabric[currentPage];
    let selectedObject = canvas.getActiveObject();

    if (selectedObject) {
      selectedObject.set("fontSize", size);
      canvas.requestRenderAll();
    } else {
      enqueueSnackbar("Please select a text", { variant: "warning" });
    } */

    let currentReplacedComponent =
      replacedComponent[currentDocumentIndex] || [];
    if (newReplacedComponent) currentReplacedComponent = newReplacedComponent;
    console.log("currentRep", currentReplacedComponent);
    // eslint-disable-next-line array-callback-return
    documentPages.map((pageInfo: any) => {
      let canvas = fabricCanvas?.fabric[pageInfo.pageNumber - 1];
      canvas.remove(...canvas.getObjects());
      // eslint-disable-next-line array-callback-return
      pageInfo.componentList.map((data: any) => {
        // console.log({ data });
        let index = currentReplacedComponent?.findIndex(
          (d: any) =>
            d.componentId === data.componentId &&
            d.uniqueId === data.uniqueId &&
            d.pageNo === pageInfo.pageNumber - 1
        );
        let imageData = currentReplacedComponent[index]?.base64;
        let signBoundingBox: number[];
        let signHeight: any;
        if (index > -1) {
          signBoundingBox = currentReplacedComponent[index].boundingBox;
          signHeight = currentReplacedComponent[index].height;
        }
        console.log("Image data", imageData, index);
        // let imageData = data.recipientSignImage;
        let isSign = true;
        if (!imageData) {
          imageData = data.imageBase64;
          isSign = false;
        }
        fabric.Image.fromURL(imageData, (img: any) => {
          img.set({
            left: data.boundingBox[0],
            top: data.boundingBox[1],
            componentId: data.componentId,
            uniqueId: data.uniqueId,
            boundingBox: data.boundingBox,
            pageNo: pageInfo.pageNumber - 1,
            isSign: isSign,
          });
          if (isSign) {
            img.scaleToHeight(signBoundingBox[3]);
            let height = data.boundingBox[3] - signHeight;
            let top = data.boundingBox[1];
            img.set("top", top + height);
            img.set("left", signBoundingBox[0]);
          } else img.scaleToHeight(data.boundingBox[3]);

          img.on("mousedown", (e: any) => {
            if (!(signData?.signImage || signData.fontId)) {
              setShowModal(true);
            }

            setCurrentSelectedComponent({
              componentId: e.target.componentId,
              boundingBox: e.target.boundingBox,
              isSign: e.target?.isSign,
              uniqueId: e.target?.uniqueId,
            });
            // img.scaleToHeight(data.boundingBox[3]);
            setSignAll(false);
            // setModal(true);
            console.log("Sign data", signData);
            addSign(
              undefined,
              {
                componentId: e.target.componentId,
                boundingBox: e.target.boundingBox,
                page: e.target.pageNo,
                uniqueId: e.target.uniqueId,
              },
              canvas
            );
          });
          img.hasControls = false;
          img.lockMovementX = img.lockMovementY = true;

          img.hoverCursor = "pointer";

          fabricCanvas?.fabric[pageInfo.pageNumber - 1]?.add(img);
        });
      });
    });
  };

  const onDocumentLoadSuccess = async (pdf: any) => {
    // console.log("OnDocumentLoadSuccess called with pdf: ", pdf);
    setNumPages(pdf.numPages);
    // setTimeout(() => {
    //   let element: any = document.querySelector(`div[data-page-number="${0}"]`);
    //   let container = document.querySelector(".doc_conainer");

    //   if (container) {
    //     container.scrollTo({
    //       top: element?.offsetTop,
    //     });
    //   }
    // }, 3000);
  };

  const onDocumentLoadError = (error: any) => {
    console.log({ error });
  };

  // const onPageLoadSuccess = async (page: any) => {
  //   // console.log("onPageLoadSuccess: page - ", page);
  // };
  let zoomfactor = currentZoomLevel / 100;
  let systemZoom = window.devicePixelRatio;
  let adjustZoom = zoomfactor / systemZoom;
  console.log("normalizedZoom", adjustZoom);

  const saveSignatures = () => {
    let zoomfactor = currentZoomLevel / 100;
    let systemZoom = window.devicePixelRatio;
    // let adjustZoom = zoomfactor / systemZoom;
    // let adjustZoom1 = 0.8;
    // setnormalizedZoom(adjustZoom1);
    //console.log("SaveSignature called. Todo: save signatures");
    let postDatas = [];
    //  console.log("replaced Components", replacedComponent, documentDetails);
    for (let i = 0; i < documentDetails.length; i++) {
      let newDocumentPages = documentDetails[i]?.documentPages.map(
        (pageInfo: any) => {
          pageInfo.componentList.map((data: any) => {
            let index = replacedComponent[i].findIndex(
              (d: any) =>
                d.componentId === data.componentId &&
                d.uniqueId === data.uniqueId &&
                d.pageNo === pageInfo.pageNumber - 1
            );
            console.log("index of componet", data.componentId, index);
            if (index >= 0) {
              data.recipientSignImage = replacedComponent[i][index].base64;
              data.boundingBox[0] = replacedComponent[i][index].boundingBox[0];
              data.boundingBox[1] = replacedComponent[i][index].boundingBox[1];
              // (onLoadZoomFactor == 100 ? systemZoom : onLoadZoomFactor);
              data.boundingBox[3] = replacedComponent[i][index].boundingBox[3];
              data.boundingBox[2] = replacedComponent[i][index].boundingBox[2];
            } else {
              data.recipientSignImage = data.imageBase64;
            }
            // data.recipientSignImage = data.imageBase64;
            data.kind = componentIdKindMap[data.componentId];
            return data;
          });
          return pageInfo;
        }
      );

      const postSigndata = {
        documentDetailsId: documentDetails[i].documentDetailsId,
        documentPages: newDocumentPages,
      };
      console.log("postSigndata", JSON.stringify(postSigndata));
      postDatas.push(postSigndata);
    }
    let saveDataPromise: any = [];
    postDatas.map((postData: any) =>
      saveDataPromise.push(saveDocumentDetails(postData))
    );
    Promise.all(saveDataPromise)
      .then((res) => {
        finish();
      })
      .catch((err) => {
        enqueueSnackbar("Failed to save sign", { variant: "error" });
      });

    // console.log("postSigndata", postSigndata);
    //  saveDocumentDetails(postSigndata);

    //console.log("Post data:", postdatas);
  };

  console.log("Replaced Components", replacedComponent);
  const finish = () => {
    // console.log(
    //   "error :" +
    //     documentDetails[currentDocumentIndex].documentPages[0]?.componentList[0]
    //       ?.recipientId
    // );
    //  console.log("finish", finish);
    const finishSigndata = {
      documentHeaderId: documentHeaderId,
      recipientId:
        documentDetails[currentDocumentIndex].documentPages[0]?.componentList[0]
          ?.recipientId,
      ipAddress: sessionStorage.getItem("ipAddress")?.toString()!,
    };
    console.log("finishSigndata", finishSigndata);
    completeSignProcess(finishSigndata);
  };

  const onPageRenderSuccess = async (pageNumber: any) => {
    setLoadedPages(loadedPages + 1);
    // console.log("onPageRenderSuccess: processing page ", pageNumber);
    // debugger;

    // fabricCanvas[currentDocumentIndex].fabric[pageNumber] = handleCanvasConvertion(
    //   `canvas-${pageNumber}`
    // );

    fabricCanvas.fabric[pageNumber] = addFabricCanvasToPage(pageNumber);
    if (pageNumber === numPages - 1) {
      setTimeout(() => {
        showComponents();
        // console.log("load after ", pageNumber);
      }, 2000);
    }
  };

  const addFabricCanvasToPage = (pageNumber: number) => {
    // let c: any = document.getElementById(canvasID);
    // let bg = c.toDataURL("image/png");
    const pageCanvasRef = fabricCanvas.pageCanvasRefs[pageNumber];
    // debugger;
    console.log("pageCanvasRef", pageCanvasRef);
    const bg = pageCanvasRef.toDataURL("image/png");
    let canvas = new fabric.Canvas(pageCanvasRef, {
      // fill: null,
    });

    canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas));
    // console.log(
    //   "handleCanvasConvertion: canvas.toDatalessJSON: ",
    //   canvas.toDatalessJSON()
    // );save
    return canvas;
  };

  // const deleteSelectedCanvasObject = () => {
  //   let canvas = fabricCanvas.fabric[currentPage];
  //   let selectedObject = canvas.getActiveObject();
  //   if (selectedObject) {
  //     canvas.remove(selectedObject);
  //   }
  // };

  const setShowModal = (value: boolean) => {
    setModal(value);
  };
  const addSign = async (
    selectedSignInfo?: object,
    component?: any,
    currentCanvas?: object
  ) => {
    //const data=JSON.parse(localStorage.getItem('sign')||" ")
    let canvas = currentCanvas;
    let selectedSignData: object = signData;
    if (selectedSignInfo) selectedSignData = selectedSignInfo;
    if (!selectedSignData) {
      // enqueueSnackbar("Please choose a sign ", { variant: "warning" });
      return;
    }
    console.log("add sign data", selectedSignData, canvas);
    if (signAll) {
      let documentPagesnew = documentPages;
      // eslint-disable-next-line array-callback-return, @typescript-eslint/no-unused-vars
      documentPagesnew = documentPagesnew.map((pageInfo: any) => {
        let canvas = fabricCanvas?.fabric[pageInfo.pageNumber - 1];
        console.log("remove all object on page", pageInfo.pageNumber - 1);
        // const objs = canvas.getObjects();
        // canvas.remove(...canvas.getObjects());
        console.log(
          "now replacing signatures on page: ",
          pageInfo.pageNumber - 1
        );
        replaceSignature(pageInfo, canvas, selectedSignData);
      });
      console.log("doc pages", documentPages);
      setDocumentPages(documentPages);
    } else {
      let replacedComponentNew = replacedComponent[currentDocumentIndex];
      if (!canvas) canvas = fabricCanvas?.fabric[currentPage];
      console.log("fabricJS", component?.page, currentPage);
      let page = component?.page;
      if (isNaN(page)) page = currentPage;
      replacedComponentNew = await replaceOneSignature(
        canvas,
        selectedSignData,
        component || currentSelectedComponent,
        page,
        replacedComponentNew
      );
      let allComponent = replacedComponent;
      allComponent[currentDocumentIndex] = replacedComponentNew;
      setReplacedComponent(allComponent);

      let comp = component || currentSelectedComponent;
      if (
        comp.componentId == signTypes.sign ||
        comp.componentId == signTypes.initial
      ) {
        // console.log("XXXXXXX", comp);
        let pageInfo =
          documentDetails[currentDocumentIndex]?.documentPages[page];
        let dateArr: any[] = [];
        pageInfo?.componentList?.map((data: any) => {
          if (data.componentId == signTypes.date) {
            let index = replacedComponent[currentDocumentIndex].findIndex(
              (d: any) =>
                d.componentId === data.componentId &&
                d.uniqueId === data.uniqueId &&
                d.pageNo === pageInfo.pageNumber - 1
            );
            if (index < 0) {
              dateArr.push(data);
            }
          }
        });
        //  console.log("XXXYYY", dateArr);
        if (dateArr.length) {
          replaceDate(dateArr, page, canvas, selectedSignData, allComponent);
        }
      }

      // console.log("==>", replacedComponentNew, signPages);
      // if (replacedComponentNew?.length == signPages.length) {
      //   console.log("All signed");
      //   saveSignatures();
      // }

      // console.log(
      //   "===>",
      //   currentSignPageIndex == signPages?.length,
      //   currentSelectedComponent?.componentId,
      //   signPages[currentDocumentIndex - 1]?.componentId
      // );
      // if (currentSignPageIndex == signPages?.length) {
      //   saveSignatures();
      // }

      setTimeout(() => {
        setIsSignedAll(isSignPresent(allComponent));
      }, 500);
    }
    //  setTimeout(()=>{showComponents()},1000)
    setCurrentSelectedComponent({});
  };

  const removeComponentFromCanvas = (
    canvas: any,
    componentId: string,
    uniqueId?: string
  ) => {
    // eslint-disable-next-line array-callback-return
    canvas.getObjects().map((obj: any) => {
      if (obj.componentId === componentId) {
        if (uniqueId && obj.uniqueId === uniqueId) {
          canvas.remove(obj);
        } else if (!uniqueId) {
          canvas.remove(obj);
        }
      }
    });
  };

  const replaceSignature = (
    pageInfo: any,
    canvas: any,
    selectedSignData: any
  ) => {
    // documentPages.map((pageInfo: any) => {

    // canvas.remove(...canvas.getObjects());
    console.log("inside replaceSignature for page: ", pageInfo.pageNumber - 1);
    let replacedComponentNew = replacedComponent[currentDocumentIndex];
    // eslint-disable-next-line array-callback-return
    pageInfo.componentList.map((data: any) => {
      replacedComponentNew = replaceOneSignature(
        canvas,
        selectedSignData,
        data,
        pageInfo.pageNumber - 1,
        replacedComponentNew
      );
    });
    let allComponent = replacedComponent;
    allComponent[currentDocumentIndex] = replacedComponentNew;
    setReplacedComponent(allComponent);

    // });
  };
  const replaceDate = async (
    componentList: any,
    pageNumber: number,
    canvas: any,
    selectedSignData: any,
    replacedComponentUpdated?: any
  ) => {
    let replacedComponentNew = replacedComponent[currentDocumentIndex];
    if (replacedComponentUpdated) {
      replacedComponentNew = replacedComponentUpdated[currentDocumentIndex];
    }
    // eslint-disable-next-line array-callback-return
    //  console.log("====", replacedComponentNew);

    for (let index = 0; index < componentList.length; index++) {
      const data = componentList[index];
      replacedComponentNew = await replaceOneSignature(
        canvas,
        selectedSignData,
        data,
        pageNumber,
        replacedComponentNew
      );
    }
    //  componentList.map((data: any) => {
    //    replacedComponentNew = replaceOneSignature(
    //      canvas,
    //      selectedSignData,
    //      data,
    //      pageNumber,
    //      replacedComponentNew
    //    );
    //  });

    // console.log("====", replacedComponentNew);
    let allComponent = replacedComponent;
    if (replacedComponentUpdated) {
      allComponent = replacedComponentUpdated;
    }
    allComponent[currentDocumentIndex] = replacedComponentNew;
    setReplacedComponent(allComponent);
  };
  const replaceOneSignature = async (
    canvas: any,
    selectedSignData: any,
    data: any,
    pageNo: number,
    replacedComponentNew: any[] = []
  ) => {
    let zoomfactor = currentZoomLevel / 100;

    if (data.isSign) return replacedComponentNew;
    console.log("PageNO", pageNo, data);
    /**
     * //base64 image data
     * initialText: "ram",
     * signImage: "base64",
     * signText: "ram"
     *
     * // font data
     * fontId: "4",
     * initialText: "ram",
     * signText: "ram"
     *
     * // complete set:
     * fontId
     * signImage
     * signText
     * initialImage
     * initialText
     *
     */

    let imageData = undefined;
    let textData = undefined;
    let fontFamily = undefined;
    let fontSize = undefined;
    console.log("replacing components list: ", data.componentId);

    switch (data.componentId) {
      case signTypes.sign:
        console.log("signType is sign");
        if (selectedSignData?.signImage?.length > 10) {
          imageData = selectedSignData.signImage;
          removeComponentFromCanvas(canvas, data.componentId, data?.uniqueId);
        } else if (selectedSignData?.signText?.length > 0) {
          textData = selectedSignData.signText;
          fontFamily = fontFamilies[selectedSignData.fontId];
          fontSize = 16;
          removeComponentFromCanvas(canvas, data.componentId, data?.uniqueId);
        }
        break;

      case signTypes.initial:
        console.log("signType is initial");
        if (selectedSignData?.initialImage?.length > 10) {
          imageData = selectedSignData.initialImage;
          removeComponentFromCanvas(canvas, data.componentId, data?.uniqueId);
        } else if (selectedSignData?.initialText?.length > 0) {
          textData = selectedSignData.initialText;
          fontFamily = fontFamilies[selectedSignData.fontId];
          fontSize = 16;
          removeComponentFromCanvas(canvas, data.componentId, data?.uniqueId);
        }
        break;

      case signTypes.text:
        console.log("signType is text, not replacing.");
        // textData = "Add Text";
        // fontSize = 30;
        // removeComponentFromCanvas(canvas, data.componentId, data?.uniqueId);
        break;

      case signTypes.date:
        console.log("signType is date. not replacing");
        textData = moment().format("MM DD YYYY HH:mm:ss");
        fontSize = 10;
        removeComponentFromCanvas(canvas, data.componentId, data?.uniqueId);

        break;

      default:
        console.log("No matching sign type..");
    }

    // console.log("imageData: ", imageData);
    // console.log("textData: ", textData);
    if (imageData) {
      fabric.Image.fromURL(imageData, async (img: any) => {
        img.set({
          left: data.boundingBox[0],
          top: data.boundingBox[1],
          componentId: data.componentId,
          uniqueId: data.uniqueId,
          pageNo: pageNo,
          isSign: true,
        });
        img.scaleToHeight(data.boundingBox[3]);
        //
        if (data.boundingBox[0] + img?.width * img.scaleY > canvas?.width) {
          img.left = canvas.width - img?.width * img.scaleY - 5;
          if (await isOverlapping(canvas, img)) {
            if (img.top - 200 > 0) img.top -= 200;
            else img.top += 200;
          }
        }
        img = await fixObjectPosition(canvas, img);
        // console.log("===canvas",canvas,img)
        img.hasControls = false;
        img.lockMovementX = img.lockMovementY = true;
        img.hoverCursor = "pointer";
        canvas.add(img);
        replacedComponentNew.push({
          componentId: data.componentId,
          boundingBox: Object.values(img.getBoundingRect()),
          uniqueId: data.uniqueId,
          base64: img.toDataURL({
            // withoutTransform: true,
            multiplier: 3,
          }),
          pageNo: pageNo,
        });
      });
    } else if (textData) {
      try {
        let properties: any = {
          left: data.boundingBox[0],
        };
        if (fontFamily) {
          properties.fontFamily = fontFamily;
        }
        if (fontSize) {
          properties.fontSize = fontSize;
        }
        // {
        // left: data.boundingBox[0],
        // top: data.boundingBox[1],
        // fontFamily: fontFamily,
        // fontSize: 20,
        // fill: data.fontColor,
        // textAlign: data.textAlign,
        // fontWeight: data.fontWeight,
        // fontStyle: data.fontStyle,
        // lineHeight: data.lineHeight,
        // textDecoration: data.textDecoration,
        // textShadow: data.textShadow,
        // stroke: data.stroke,
        // }
        let textObj: any = new fabric.IText(textData, properties);
        textObj.hasControls = false;
        textObj.componentId = data.componentId;
        textObj.uniqueId = data.uniqueId;
        textObj.pageNo = pageNo;
        textObj.isSign = true;
        console.log("built textObj: ", textObj);
        //the Top is set here
        let height = data.boundingBox[3] - textObj.height;
        let zoomfact = data.boundingBox[1];
        textObj.set("top", zoomfact + height);
        textObj.hasControls = false;
        textObj.lockMovementX = textObj.lockMovementY = true;
        textObj.hoverCursor = "pointer";

        canvas.add(textObj);

        // canvas.forEachObject(function (obj: any) {
        //   if (obj === textObj) return;
        //   if (
        //     obj?.isSign &&
        //     (textObj.isContainedWithinObject(obj) ||
        //       textObj.intersectsWithObject(obj) ||
        //       obj.isContainedWithinObject(textObj))
        //   ) {
        //     let isRightSide = textObj.left - obj.left;

        //     if (isRightSide > 0) {
        //       textObj.left = obj.left + obj.width + 5;
        //       console.log("===>right");
        //     } else {
        //       console.log("===>left");
        //       textObj.left = obj.left - textObj.width - 5;
        //     }
        //   }
        // });

        console.log("-------------------");
        //=======================================
        if (data.boundingBox[0] + textObj?.width > canvas?.width) {
          textObj.left = canvas.width - textObj?.width - 5;
          if (await isOverlapping(canvas, textObj)) {
            if (textObj.top - 30 > 0) textObj.top -= 30;
            else textObj.top += 30;
          }
        }

        textObj = await fixObjectPosition(canvas, textObj);

        let newComp = {
          componentId: data.componentId,
          uniqueId: data.uniqueId,
          boundingBox: Object.values(textObj.getBoundingRect()),
          base64: textObj.toDataURL({
            multiplier: 3,
          }),
          pageNo: pageNo,
          height: textObj.height,
        };

        let dbArr = newComp.boundingBox;
        dbArr[0] = textObj.left;
        newComp.boundingBox = dbArr;

        replacedComponentNew.push(newComp);
      } catch (ex) {
        console.log("error in fabric itext", ex);
      }
    }

    return replacedComponentNew;
  };
  const fixObjectPosition = async (canvas: any, fabObject: any) => {
    canvas.forEachObject(function (obj: any) {
      if (obj === fabObject) return;

      if (
        obj?.isSign &&
        (fabObject.isContainedWithinObject(obj) ||
          fabObject.intersectsWithObject(obj, true, true) ||
          obj.isContainedWithinObject(fabObject))
      ) {
        let objWidth = obj.width * obj.scaleY;
        let fabwidth = fabObject.width * fabObject.scaleY;

        let isRightSide = fabObject.left + fabwidth - (obj.left + objWidth);

        if (isRightSide > 0) {
          if (obj.left + objWidth + fabwidth < canvas?.width)
            fabObject.left = obj.left + objWidth + 5;
          else fabObject.left = obj.left - fabwidth - 5;
          // console.log("===inrght");
        } else {
          if (obj.left - fabwidth - 5 > 0)
            fabObject.left = obj.left - fabwidth - 5;
          else fabObject.left = obj.left + objWidth + 5;
          // console.log("===inleft");
        }
      }
    });
    if (await isOverlapping(canvas, fabObject)) {
      if (fabObject.top - 20 > 0) fabObject.top -= 20;
      else fabObject.top += 20;
    }
    return fabObject;
  };

  const isOverlapping = async (canvas: any, fabObject: any) => {
    let resArr = [];
    canvas.forEachObject(function (obj: any) {
      if (obj === fabObject) return;
      if (obj?.isSign && fabObject.intersectsWithObject(obj, true, true)) {
        resArr.push(1);
      }
    });
    return resArr.length ? true : false;
  };

  console.log("documentDetails", documentDetails);
  const pageScroller = () => {
    let replacedComponentLocal = replacedComponent;
    console.log("Page scroll", currentSignPageIndex);
    if (currentSignPageIndex < signPages?.length) {
      scrollToPage(signPages[currentSignPageIndex]);
      setCurrentSignPageIndex((prev) => prev + 1);
      setReviewBtnText("Next");
      // setMovingBtnTop("250")
    } else {
      /* if (isSignedAll) {
        setReviewBtnText("Finish");
      } else {
        setReviewBtnText("Top");
      } */

      if (
        componentListCount[currentDocumentIndex].length ==
          replacedComponentLocal[currentDocumentIndex].length &&
        currentDocumentIndex < documentDetails.length - 1
      ) {
        setReviewBtnText("Go To Next Document");
      } else if (
        componentListCount[currentDocumentIndex].length ==
          replacedComponentLocal[currentDocumentIndex].length &&
        currentDocumentIndex == documentDetails.length - 1
      ) {
        setReviewBtnText("Finish");
      } else {
        setReviewBtnText("Top");
      }
      if (reviewBtnText === "Go To Next Document") {
        goToAntherDocument();
      }

      if (reviewBtnText === "Top") {
        scrollToPage(signPages[0]);
        // saveSignatures();
        // setSignAll(true);
        // setModal(true);
        setCurrentSignPageIndex(0);
        setReviewBtnText("Start");
      }
      if (reviewBtnText === "Finish") {
        saveSignatures();
      }
    }
  };
  const scrollToPage = (pageData: any) => {
    let page = pageData.pageNumber;
    let offset = pageData.boundingBox[1];
    let element: any = document.querySelector(
      `div[data-page-number="${page}"]`
    );
    // element?.scrollIntoView({ behavior: "smooth" });
    //  element?.scrollTo({ top: 50, behavior:
    //     'smooth'
    //   })
    // console.log("Elemwnt",element?.offsetTop)
    let container = document.querySelector(".doc_conainer");
    // console.log("Container", container?.clientHeight);

    if (container) {
      // let mid: number = 0;
      // if (container.clientHeight) mid = container.clientHeight / 2;
      //container.scrollTop= element?.offsetTop+offset
      //  console.log("PN OFFSET", pageData.pageNumber, offset);
      container.scrollTo({
        top: element?.offsetTop + offset,
        behavior: "smooth",
      });
    }
    // element?.scrollBy({top:100})
  };
  const getSelected = () => {
    let canvas = fabricCanvas?.fabric[currentPage];
    let selectedObject = canvas?.getActiveObject();

    if (selectedObject) {
      // console.log("selected object", selectedObject);
      setCurrentSelectedComponent({
        componentId: selectedObject.componentId,
        boundingBox: selectedObject.boundingBox,
        isSign: selectedObject?.isSign,
        uniqueId: selectedObject?.uniqueId,
      });
    } else {
      // enqueueSnackbar("Please select a component ", { variant: "warning" });
    }
  };
  /**
   * Function to reset the selected sign component
   */
  const resetSignature = () => {
    let canvas = fabricCanvas?.fabric[currentPage];
    let selectedObject = canvas?.getActiveObject();
    // debugger;
    if (selectedObject) {
      console.log(selectedObject);
      let replacedComponentNew = replacedComponent[currentDocumentIndex];
      let allComponents = replacedComponent;

      replacedComponentNew = replacedComponentNew?.filter(
        (d: any) =>
          !(
            d.pageNo == selectedObject.pageNo &&
            d.componentId == selectedObject.componentId &&
            d.uniqueId == selectedObject.uniqueId
          )
      );
      allComponents[currentDocumentIndex] = replacedComponentNew;
      setReplacedComponent(allComponents);
      canvas.remove(selectedObject);
      showComponents(replacedComponentNew);
      // console.log('replacednew',selectedObject,replacedComponent[currentDocumentIndex],replacedComponentNew)
      setIsSignedAll(false);
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  // const [isModalOpen, setModalOpen] = useState(true);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const userName = "Yogibhfbhcjjhc";
  const userEmail = "emaiwenuhifewjuflyogir@gsdhuimail.com";

  return (
    <>
      {loading ? (
        <div className="bg-primary-1 mt-2 roundedcurrentDocumentId relative w-4/5 m-auto flex items-center justify-center p-4">
          <div className="w-full">
            <LinearProgress
              color="inherit"
              style={{
                backgroundColor: "red",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="overflow-y-auto overflow-x-hidden">
          <div className="bg-primary-900 mt-2 mb-4 roundedcurrentDocumentId relative w-full  m-auto">
            <div className="p-2 w-1/4 text-black">
              Recipient Name:
              {documentDetails[currentDocumentIndex]?.recipientName}
            </div>
            <div
              className="flex flex-col justify-center items-center"
              style={{ border: "0px solid red " }}
            >
              <div
                className="  text-black"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontStyle: "italic",
                }}
              >
                {documentDetails[currentDocumentIndex]?.convertedDocumentName}
                {totalDocumentsLength > 1 ? (
                  <span>
                    ({CurrentActiveDocument}/{totalDocumentsLength})
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="w-1/12 ">
                <Chip
                  label={`active page ${currentPage + 1}`}
                  className="chip"
                />
              </div>
            </div>

            <div className="relative p-2 w-full mt- mx-auto flex items-start justify-center">
              <div className="flex items-center justify-center flex-col">
                <div
                  onClick={() => {
                    getSelected();
                    setSignAll(false);
                    setShowModal(true);
                  }}
                  className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
                >
                  <FaSignature color="black" size={25} />
                  <p className="text-black mt-2 text-center text-[0.8rem]">
                    Choose Sign
                  </p>
                </div>

                <div
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to Reset All?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result: any) => {
                      if (result.isConfirmed) {
                        let obj = replacedComponent;
                        delete obj[currentDocumentIndex];
                        /*   setReplacedComponent({
                          ...replacedComponent,
                          [currentDocumentIndex]: [],
                        }); */
                        setReplacedComponent(obj);
                        showComponents([]);
                        setIsSignedAll(false);
                        setReviewBtnText("Start");
                      }
                    });
                  }}
                  className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
                >
                  <FaUndo color="black" size={25} />
                  <p className="text-black mt-2 text-center text-[0.8rem]">
                    Reset All
                  </p>
                </div>
                {/* 
                <div
                  onClick={() => {
                    saveSignatures();
                  }}
                  className="w-full cursor-pointer flex items-center justify-center flex-col border border-black rounded-md p-2 mb-2 opacity-80"
                >
                  <FaSave color="black" size={25} />
                  <p className="text-black mt-2 text-center text-[0.8rem]">
                    Save Signature
                  </p>
                </div> */}
                <div
                  onClick={resetSignature}
                  style={{
                    border: "1px solid green ",
                    backgroundColor: "#941F23",
                    borderRadius: "5px",
                  }}
                  className="mb-2 w-full pt-2 pb-2 flex  cursor-pointer justify-center items-center"
                >
                  <div
                    className=""
                    style={{ color: "#fff", fontWeight: "bold" }}
                  >
                    RESET
                  </div>
                </div>
                <Button
                  variant="contained"
                  disabled={!isSignedAll}
                  onClick={saveSignatures}
                >
                  Finish and close
                </Button>
              </div>
              <div
                style={{
                  position: "relative",
                  backgroundColor: "#409A4D",
                  color: "#fff",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  // top:`${movingBtnTop}px`
                }}
                className=" ml-2 "
              >
                <div
                  className="pl-4 flex items-center pr-4 pt-1 pb-1 cursor-pointer"
                  onClick={() => {
                    pageScroller();
                  }}
                >
                  {reviewBtnText} <FaAngleRight size={12} />
                </div>
              </div>

              <div className="w-4/6 ">
                <div
                  className="doc_conainer overflow-auto border-secondary  bg-primary-100 p-2 rounded-5 border-opacity-70 relative drop-shadow-md"
                  style={{ height: "85vh", paddingBottom: "486px" }}
                >
                  <div className="Example__container overflow-y-auto overflow-x-hidden">
                    <div
                      className="Example__container__document__title"
                      // style={{ height: "50vh" }}
                    >
                      <h3>
                        {/* {currentDocument && currentDocument.convertedDocumentName} */}
                      </h3>
                    </div>
                    <div className="Example__container__document">
                      <Document
                        file={
                          documentDetails[currentDocumentIndex]
                            ?.convertedDocumentPath
                        }
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                        onSourceError={onDocumentLoadError}
                        loading="Loading file..."
                        noData="No PDF file specified."
                      >
                        {Array.from(new Array(numPages), (_el, index) => {
                          return (
                            <Page
                              scale={normalizedZoom}
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                              canvasRef={(ref) => {
                                // console.log(
                                //   "updating canvasref",
                                //   index,
                                //   fabricCanvas[currentDocumentIndex]
                                // );
                                if (fabricCanvas) {
                                  // console.log("updating canvasref good", index);
                                  fabricCanvas.pageCanvasRefs[index] = ref;
                                } else {
                                  // console.log("updating canvasref bad", index);
                                }
                              }}
                              // canvasBackground="transparent"
                              onClick={() => {
                                console.log("currentPage", index);
                                setCurrentPage(index);
                              }}
                              onRenderSuccess={() => {
                                // console.log(
                                //   "page render success called: ",
                                //   index
                                // );
                                onPageRenderSuccess(index);
                              }}
                            />
                          );
                        })}
                      </Document>
                    </div>
                    {/* <p className="text-black"></p> */}
                  </div>
                </div>
              </div>

              <div
                className=" overflow-y-auto overflow-x-hidden   "
                style={{ height: "83vh" }}
              >
                <div className="p-4 ">
                  {documentDetails &&
                    documentDetails?.map((doc: any, idx: any) => (
                      <div key={doc.id}>
                        <p
                          style={{
                            color:
                              CurrentActiveDocument - 1 === idx
                                ? "#409A4D"
                                : "black",
                          }}
                          className="text-black mt-2 font-semibold mb-1 text-base"
                        >
                          {idx + 1}
                        </p>
                        <div
                          style={{
                            borderRadius: "10px",
                            boxShadow:
                              CurrentActiveDocument - 1 === idx
                                ? "1px 1px 5px 1px #409A4D"
                                : "",
                            border:
                              CurrentActiveDocument - 1 === idx
                                ? "3px solid  #409A4D"
                                : "3px solid black",
                          }}
                          onClick={() => {
                            if (currentDocumentIndex === idx) {
                              // console.log("Reselecting current document only..");
                              return false;
                            }
                            // saveSignatures();
                            // setLoading(true);
                            // handleSave(doc, idx);
                            // setCurrentDocument(doc);
                            setCurrentDocumentIndex(idx);
                            updateCurrentdocumentIndex(doc);
                            setDocumentPages(
                              documentDetails[idx]?.documentPages
                            );
                            setCurrentPage(0);
                            // let pageArr = documentDetails[
                            //   idx
                            // ]?.documentPages?.map((obj: any) => obj.pageNumber);
                            // setSignPages(
                            //   pageArr?.sort((a: number, b: number) => a > b)
                            // );
                            arrangeSignPages(
                              documentDetails[idx]?.documentPages
                            );
                            setCurrentSignPageIndex(0);
                            setReviewBtnText("Start");
                            // setReplacedComponent([]);
                          }}
                          className="bg-primary-bg w-[7rem] h-[10rem] cursor-pointer flex items-center justify-center flex-col border border-black rounded p-2 mb-2 opacity-80"
                        >
                          <p
                            style={{
                              color:
                                CurrentActiveDocument - 1 === idx
                                  ? "#409A4D"
                                  : "black",
                            }}
                            className="text-black  break-all"
                          >
                            {doc.convertedDocumentName}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {modal && (
              <SignPopupLocal
                setShowModal={setShowModal}
                modal={modal}
                name={documentDetails[currentDocumentIndex]?.recipientName}
                createSignCallback={addSign}
                setSignData={setSignData}
              />
            )}
            {console.log("setSignData", signData)}

            <MainModal
              isOpen={isModalOpen}
              onClose={closeModal}
              userName={userName}
              userEmail={userEmail}
            />
          </div>
        </div>
      )}
    </>
  );
};
