import React, { useContext, useState, useRef, useEffect } from "react";
import { NavLink as Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import SignatureContext from "../../context/signature/Signature.context";
import { ProfilePopup } from "./ProfilePopup";
import { PlansPopup } from "./PlansPopup";
import { useSnackbar } from "notistack";
import { FaBell } from "react-icons/fa";

interface HeadersProps {}

export const Headers: React.FC<HeadersProps> = () => {
  const { pathname } = useLocation();
  const { userName } = useContext(SignatureContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profilePopupOpen, setProfilePopupOpen] = useState(false);
  const [plansPopupOpen, setPlansPopupOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const notificationsMenuRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
    enqueueSnackbar("Successfully logged out!", { variant: "success" });
    handleClose();
  };

  const handleProfileClick = () => {
    setProfilePopupOpen(true);
    handleClose();
  };

  const handlePlansClick = () => {
    setPlansPopupOpen(true);
    handleClose();
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      notificationsMenuRef.current &&
      !notificationsMenuRef.current.contains(event.target as Node)
    ) {
      setNotificationsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const notifications = [
    { id: 1, message: "📧 Your document has been sent for signing." },
    { id: 2, message: "📝 You have a new document to review." },
    { id: 3, message: "✅ Document signing completed successfully!" },
    { id: 4, message: "🔄 Document status has been updated." },
    { id: 5, message: "🚀 Document has been finalized." },
    { id: 6, message: "⏳ Reminder: Document signing is due soon." },
    { id: 7, message: "❗ Action required: Review document details." },
  ];

  // Inline styles
  const notificationsMenuStyle: React.CSSProperties = {
    display: notificationsOpen ? "block" : "none",
    position: "absolute",
    top: "60px", // Adjust as needed
    right: "10px", // Adjust as needed
    background: "white",
    border: "1px solid #ddd",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    width: "250px", // Adjust as needed
    zIndex: 1000, // Ensure the notifications menu is on top
  };

  const listItemStyle: React.CSSProperties = {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    color: "black", // Text color black
  };

  const lastListItemStyle: React.CSSProperties = {
    ...listItemStyle,
    borderBottom: "none",
  };

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap bg-primary-1 p-2 lg:px-8 shadow-lg">
        <Link
          to="/home"
          className="flex items-center flex-no-shrink text-black mr-6"
        >
          <span className="font-semibold text-2xl tracking-tight">
            EasySign
          </span>
        </Link>
        <div className="block lg:hidden">
          <button className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
            <svg
              className="h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          {pathname !== "/login" && (
            <div className="text-sm lg:flex-grow">
              <Link to="/home" className="mainTitle">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-base">
                  Home
                </span>
              </Link>
              <Link to="/manage" className="mainTitle">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-base">
                  Manage
                </span>
              </Link>

              {/* <Link to="/template" className="mainTitle">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-white mr-4 text-base">
                  Template
                </span>
              </Link> */}
            </div>
          )}
          {pathname !== "/login" && (
            <div className="flex items-center relative">
              <span className="text-black pr-4 cursor-default">
                {userName}
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {/* 20 days Left */}
                </span>
              </span>

              {/* <IconButton onClick={toggleNotifications}>
                <FaBell size={24} />
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {notifications.length}
                </span>
              </IconButton> */}

              <IconButton onClick={handleClick}>
                <Avatar
                  alt="Profile"
                  src="https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                <MenuItem onClick={handlePlansClick}>Plans</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
              <div ref={notificationsMenuRef} style={notificationsMenuStyle}>
                <ul
                  style={{ listStyleType: "none", padding: "0", margin: "0" }}
                >
                  {notifications.map((notification) => (
                    <li
                      key={notification.id}
                      style={
                        notification.id === notifications.length
                          ? lastListItemStyle
                          : listItemStyle
                      }
                    >
                      {notification.message}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </nav>
      {/* Profile Popup */}
      <ProfilePopup
        open={profilePopupOpen}
        onClose={() => setProfilePopupOpen(false)}
      />
      {/* Plans Popup */}
      <PlansPopup
        open={plansPopupOpen}
        onClose={() => setPlansPopupOpen(false)}
      />
    </>
  );
};
